import React from 'react'
import Navbar from '../inc/Navbar'
import grouploan from "../images/group loan banner jpg.jpg";
import Grouploan from './Grouploan';
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Grouploanpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon"/>
        <img src={grouploan} className="d-block w-100" alt="apmcfinance"/>

<div className="album py-1">

    <div className="container mt-2">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Small Group Loan</li>
            </ol>
        </nav>
    </div>
</div>
<Grouploan/>
    </div>
  )
}

export default Grouploanpage