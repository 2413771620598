
import './App.css';
import React, { useEffect} from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Microloan from './components/pages/Microloan';
import Contactus from './components/pages/Contactus';
import ApplyLoan from './components/pages/ApplyLoan';
import PersonalLoan from './components/pages/PersonalLoan';

import Grouploanpage from './components/pages/Grouploanpage';
import PloanRule from './components/pages/PloanRule';
function App() {
  return (
    <Router>
    
    <div>
    
      <Routes>
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/small-business-micro-loan" element={<Microloan/>}/>
      <Route axact path="/personal-micro-loan" element={<PersonalLoan/>}/>
      <Route axact path="/small-group-loan" element={<Grouploanpage/>}/>
      <Route axact path="/personal-loan-rules" element={<PloanRule/>}/>


      <Route axact path="/contact-us" element={<Contactus/>}/>
      <Route axact path="/apply-loan" element={<ApplyLoan/>}/>
                       
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;
