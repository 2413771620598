import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import ScrollToTop from "react-scroll-to-top";
import "./Home.css";
import micro from "../images/micro loan page.jpg";

import agri from "../images/agriculture-loan.jpg";
import prosuctloan from "../images/products_loan.jpg";
import grouploan from "../images/group-loan.jpg";

import NoticeUpdate from './NoticeUpdate';
import Grouploan from './Grouploan';
import grouploannew from "../images/group loan banner jpg.jpg";

function Home() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="darkBlue" />

      <div className="album py-2">
        <div className="container">
          <h5>Apmc Finance</h5>
        </div>
      </div>
      <Slider />
      <div className='marqueeback'>
        <div className="album py-0">
          <div className="container">
            <div className="row">
              <marquee>
                <p className='mt-2' style={{ color: "white" }}>Latest news APMC Finance Launch this month & help to people area of work</p>
              </marquee>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container">
          <h3 style={{ color: "grey" }}>Bank easier with our Comprehensive <b>Products & Services</b></h3>
        </div>
      </div>
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-3">
              <div className='containerloan'>
                <a href='small-business-micro-loan'>
                  <div className="card">
                    <img src={micro} className="card-img-top mb-0 image" alt="Small Business Micro Loan"/>
                    <center>
                      <div className="card-body">
                        <div className='colortext'>
                          <h4 className="card-title"><b>  Small Business Micro Loan  </b></h4>
                        </div>
                        <p className="card-text mt-2">Achieve your fianancial goal</p>
                        <div class="middle">
                          <div class="text">Small Business Micro Loan<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                          </svg></div>
                        </div>
                      </div>
                    </center>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className='containerloan'>
                <a href='small-group-loan'>
                  <div className="card">
                    <img src={grouploan} className="card-img-top mb-0 image" alt="grouploan" />
                    <center>
                      <div className="card-body">
                        <div className='colortext'>
                          <h4 className="card-title"><b>Small Group Loan  </b></h4>
                        </div>
                        <p className="card-text mt-2">Small Group Lon for Women</p>
                        <div class="middle">
                          <div class="text">Small Group Loan<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                          </svg></div>
                        </div>
                      </div>
                    </center>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className='containerloan'>
                <a href='personal-micro-loan'>
                  <div className="card">
                    <img src={prosuctloan} className="card-img-top mb-0 image" alt="productloan" />
                    <center>
                      <div className="card-body">
                        <div className='colortext'>
                          <h4 className="card-title"><b> Personal Micro Loan  </b></h4>
                        </div>
                        <p className="card-text mt-2">Achieve your fianancial goal</p>
                        <div class="middle">
                          <div class="text"> Personal Micro Loan <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                          </svg></div>
                        </div>
                      </div>
                    </center>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="col-md-3 mt-3">
              <div className='containerloan'>
                <a href='small-loan'>
                  <div className="card">
                    <img src={smallloan} className="card-img-top mb-0 image" alt="smallloan" />
                    <center>
                      <div className="card-body">
                        <div className='colortext'>
                          <h4 className="card-title"><b>Small Loan  </b></h4>
                        </div>
                        <p className="card-text mt-2">Achieve your Small goal</p>
                        <div class="middle">
                          <div class="text">Small Loan<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                          </svg></div>
                        </div>
                      </div>
                    </center>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <br />

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mt-3">
              <div class="card border-priamry text-white mb-3" >
                <div class="card-header"><h4 style={{ color: "darkBlue" }}><center><b> IMPORTANT LINK</b></center></h4></div>
                <div class="card-body ">

                  <a href='apply-loan'>
                    <div className="headlink me-2  mb-2">Apply Loan </div></a >
                  <a href='contact-us'>
                    <p className="headlink me-2 mb-2" >  <small>Contact us</small> </p>
                  </a>
                  <a className="headlink me-0 mb-2 me-2" href="/personal-micro-loan"> <small>  Personal Micro Loan</small></a>
                </div>
              </div>
            </div>

            <div className="col-md-5 mt-3">
              <div className="card border-dark mb-3" >
                <div className="card-header"><center>NOTICE BORARD</center></div>
                <div className="card-body text-white">
                  <NoticeUpdate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <img src={grouploannew} className="d-block w-100" alt="apmcfinance" />
      <Grouploan />
      <br />

      <br />

    </div>
  )
}

export default Home