



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCrhvbbDjMn1Ga_5pkuNepAkYxSqAOsb1w",
  authDomain: "apmc-finance.firebaseapp.com",
  projectId: "apmc-finance",
  storageBucket: "apmc-finance.appspot.com",
  messagingSenderId: "18203637353",
  appId: "1:18203637353:web:f96976aae93a35223154f8",
  measurementId: "G-GJWKQB5BD0"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



