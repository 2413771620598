import React from 'react'
import Navbar from '../inc/Navbar'
import AddApplyloan from '../AddApplyloan'
import logoapply from "../images/logo - apmc finance.jpg";

function ApplyLoan() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
        
            <div className='colorb'>
            <br />
            <br />
            <br/>
                <div className="album py-0">
                    <div className="container">
                        <div className="card" >
                            <center>
                            <img src={logoapply} className="mt-3" width="300px"  height="100px" alt="logoapply"/>
                            </center>

                            <div className="card-body">
                            <center>
                                <h5 className="card-title">Loan Application</h5>
                                <p className="card-text mb-4">Enter your PAN number for identity verification and loan processing.</p>
                                </center>
                                <AddApplyloan/>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    )
}

export default ApplyLoan