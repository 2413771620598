import React from 'react'
import AddApplyloan from '../AddApplyloan';
import Table from 'react-bootstrap/Table';


function Grouploan() {
    return (
        <div>
           
            <br />



            <div className="album py-3">
                <div className="container">
                    <h1 style={{ color: "darkBlue" }}>Small Group Loan</h1> <br />
                    <h5 className='mb-4'>APMC Finance Small Group Loan is designed to extend loan facilities to women, with the objective of helping them meet their financial needs.</h5>
                    <h6 style={{ color: "darkBlue" }}><b>Key Benefits</b></h6>
                </div>
            </div>


            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mt-3">
                            <Table striped bordered hover variant="light">
                                <thead>
                                    <tr>
                                        <th>FEATURES	</th>
                                        <th>ATTRIBUTES        </th>

                                    </tr>
                                </thead>


                                <tbody>
                                    <tr>
                                        <td>Loan Amount</td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" class="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg> Minimum amount is INR 15,000 <br />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "maroon" }} className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                            </svg> Maximum amount is INR 50,000</td>

                                    </tr>
                                    <tr>
                                        <td>Age of Applicant	          </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "maroon" }} className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg>  Between 18 to 58 years</td>

                                    </tr>
                                    <tr>
                                        <td>Loan Tenure          </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "maroon" }} className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg>  Maximum 24 months          </td>

                                    </tr>
                                    <tr>
                                        <td> Processing Fee	        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "maroon" }} className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg>  0 - 2% plus applicable GST         </td>

                                    </tr>
                                    <tr>
                                        <td>Interest Rates	                                        </td>
                                        <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "maroon" }} className="bi bi-check-circle me-3" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                        </svg> Enjoy highly competitive interest rates ranging from 22% - 26% p.a.        </td>

                                    </tr>
                                </tbody>
                            </Table>

                        </div>
                        <div className="col-md-5 mt-3">
                            <div className="card">
                                <div style={{ color: "darkBlue" }} class="card-header"><center><b>Check your eligibility</b></center></div>
                                <div className="card-body">
                                    <center><h2 className='mb-3' style={{ color: "darkBlue" }}><b>Apply Now</b></h2></center>
                                    <AddApplyloan />

                                </div>
                            </div>
                        </div>
                    </div>
                  
                    
                </div>

            </div>
            <div className="album py-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <hr/>
            <div className='textcolor'>
                        <h3 className='mb-4'>Eligibility Criteria                </h3>
                        <h6 className='mb-3'>Applicants must meet the following requirements to be eligible for the loan:</h6>
                        <h6 className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Should fulfil KYC documents requirements</h6>
                        <h6 className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Age should be between 18 to 58 years</h6>
                        <h6 className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Should not have an adverse credit record</h6>
                        <h6 className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Should meet the acceptable credit norms according to APMC Finance </h6>

                        <h6 className='mb-3'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Should be part of a group of customers who are willing to guarantee each other for the repayment of loan</h6>
                        <br />
                        <hr />


                        <br />
                        <h3 className='mb-3'>How to Apply</h3>
                        <h6 className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Visit the nearest branch of APMC Finance and our Loan Executive will guide you on the application process.</h6>

                        <h6 className='justify mb-4'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg>     Contact us and our Customer Care Executive will fix a doorstep service appointment with our Loan Executive to help you with the application process.</h6>
                        <hr/>
                        <br/>
                        <h4 className='mb-4' style={{color:"darkBlue"}}><b>Required Documents          </b>    </h4>
                        <h6 className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Primary ID: Aadhaar                        </h6>
                        <h6 className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Secondary ID: PAN card / Form 60</h6>
                        <h6 className='mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> In case of non-availability of PAN card, customer needs to submit Form 60 along with the one of the following OVDs (Officially Valid Documents)
                        </h6>
                        <div className="album py-0">
                        <div className="container">
                       
                            <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Passport</h6>
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Voter ID card                        </h6>
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Driving licence</h6>
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> NREGA (National Rural Employment Guarantee Act)</h6>
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Letter issued by National Population Register containing details of name and address</h6>
                            </div>
                            </div>
                            <br/>
                            
<h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Residence Proof:</h6>
                        <div className="album py-0">
                        <div className="container">
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Any of the valid KYC documents</h6>
                        <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "maroon" }} fill="currentColor" className="bi bi-check2-all me-4" viewBox="0 0 16 16">
                            <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                            <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                        </svg> Utility bill (not more than 60 days old)</h6>
                       </div>
                       </div>
                       <br/>
                       <p>For Terms and Conditions, please contact the nearest APMC Finance branch.</p>

                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

            <br />
        </div>
    )
}

export default Grouploan