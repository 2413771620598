import React from 'react'
import Navbar from '../inc/Navbar'
import microloan from "../images/Small Business Micro Loan.jpg";

import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function Microloan() {
    return (
        <div>
            <Navbar />
            <br />
            <br />

            <ScrollToTop smooth top="100" color="darkBlue"/>
            <div className="album py-2">
                <div className="container">
                    <h5>Apmc Finance</h5>
                </div>
            </div>

            <img src={microloan} className="d-block w-100" alt="microloan" />
            <div className='murshidabadfinance'>
                <div className="album py-0">
                    <div className="container ">
                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                    <li className="breadcrumb-item"><a href="apply-loan">Apply Loan</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Small Business Micro Loan</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br/>
            <div className="album py-1">
                <div className="container ">
                    <h2>Small Business Micro Loans</h2>
                    <h6>You are a part of a community. You and your neighbors have dreams. You want to start a business, invest in your education, or cover unexpected expenses. But you don't have access to traditional loans. Micro group loans can help.</h6>
                </div>
            </div>
            <br/>
            <div className="p-3 mb-2 bg-light text-dark">

                <div className="album py-3">
                    <div className="container">
                        <h3>Featurse and Benefits of a Small Business Micro Loan</h3>
                       
<br/>
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
   <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Loan up to Rs 1 lakh</b></h6>
<p className='mb-4'>Member are eligible to get maximum of Rs. 1,00,000/- loan depending upon loan eligibility and cycle. Loans are given only for income generating activities such as poultry, fisheries, animal husbandry, vegetable vendor, papad, small cottage industries etc.</p>
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Flexible repayment tenure</b></h6>
<p className='mb-4' >Member has the option to pay the EMI on weekly, fortnightly or monthly basis. Although monthly emi is the most acceptable mode of repayment.</p>
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> No collateral</b></h6>
<p className='mb-4' >Member can avail collateral free loan under Small Business Micro Loan loan of APMC Finance. It is based on JLG model where social collateral is required to avail loan from the organization.</p>                       
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Doorstep onboarding and collection process</b></h6>
<p className='mb-4' >All the activities in APMC Finance Small Business Micro Loan product is done at member house of village except disbursement</p>                       
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Insurance coverage</b></h6>

<p className='mb-4' >Members and her husband are given insurance coverage till the loan tenure. Sum insured is equal to the loan amount disbursed to the customer.</p>                       
<h6><b><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
  <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0"/>
  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708"/>
</svg> Disbursement in saving account in 3 days</b></h6>
<p className='mb-4' >The whole process of customer onboarding till disbursement in APMC Finance take 3 days which is among the best in the industry.</p>                       

                    </div>
                </div>
                {/* <div className="album py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 me-5 mt-2">
                     <h3 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg> Business Loan.</h3>
<hr/>
                            </div>
                            <div className="col-md-5 mt-2">
                     <h3 style={{color:"grey"}}><svg xmlns="http://www.w3.org/2000/svg" style={{color:"green"}} width="24" height="24" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg> Emergency Loan
                     </h3>
                     <hr/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <br />
            <br />
            
     

        </div>
    )
}

export default Microloan