import React from 'react'
import Navbar from '../inc/Navbar'
import partsloan from "../images/loan-parts.jpg"
import { Link } from 'react-router-dom'
import AddApplyloan from '../AddApplyloan'
import homegril from "../images/hme-gril.jpeg";
import backholderrepair from "../images/Tracktor Backholder.jpg";
import spareparts from "../images/spare parts.jpg";

import backhoeparts from "../images/BACKHOE PARTS.jpg";

import ScrollToTop from "react-scroll-to-top";


function PersonalLoan() {
    return (
        <div>
            <Navbar />

            
            <br />
            <br />
            <br />
            <br />
            
            <ScrollToTop smooth top="100" color="maroon"/>
            <div className="financeloanberhampore">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="#" href="#"> Personal Micro Loan</Link></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="album py-3">
                <div className="container">
                    <div className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-4 me-4">
                                <img src={homegril} className="img-fluid rounded-start" alt="apmcfinance"/>
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title"> <b> Personal Micro Loan</b>   </h5>
                                    <h6 className="card-text mb-3"> Personal micro loan for home iron grill window & door</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="album py-2">
                <div className="container">
                    <div className="card mb-3" >
                        <div className="row g-0">
                            <div className="col-md-4 me-4">
                                <img src={backholderrepair} className="img-fluid rounded-start" alt="backholderrepair" />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title"> <b> Personal Micro Loan</b>   </h5>
                                    <h6 className="card-text mb-3"> Personal micro loan for excavator spare parts & repairing loan</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="card mb-3" >
                        <div className="row g-0">
                            <div className="col-md-4 me-4">
                                <img src={spareparts} className="img-fluid rounded-start" alt="spareparts"/>
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title"> <b> Personal Micro Loan</b>   </h5>
                                    <h6 className="card-text mb-3"> Personal micro loan for tractor spare parts purchase & repairing loan</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="album py-2">
                <div className="container">
                    <div className="card mb-3" >
                        <div className="row g-0">
                            <div className="col-md-4 me-4">
                                <Slider2 />
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title"> <b> Personal Loan</b>   </h5>
                                    <h6 className="card-text mb-3"> personal loan for tractor trolley purchase loan</h6>
                                    <p>Total Loan Minimum - 10,000 , Maximum- 1,25,000</p>
                                    <a href="#" className="apmcloanberhampore">View Detials <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            
            <div className="album py-2">
                <div className="container">
                    <div className="card mb-3" >
                        <div className="row g-0">
                            <div className="col-md-4 me-4">
                            <img src={backhoeparts} className="img-fluid rounded-start" alt="apmc finance"/>
                            </div>
                            <div className="col-md-7">
                                <div className="card-body">
                                    <h5 className="card-title"> <b> Personal Micro Loan</b>   </h5>
                                    <h6 className="card-text mb-3">Personal micro loan for Backhoe loader spare parts purchase and repairing loan</h6>
                                    <p>Total Loan Minimum - 10,000 , Maximum- 1,25,000</p>
                                    <a href="#" className="apmcloanberhampore">View Detials <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                        <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={homegril} className="card-img-top" alt="homegril" />
                                <div className="card-body">
                                    <h5 className="card-title"><b> Personal Loan</b></h5>
                                    <h6 className="card-text mb-3"> personal loan for home iron grill window & door</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={homegril} className="card-img-top" alt="homegril" />
                                <div className="card-body">
                                    <h5 className="card-title"><b> Personal Loan</b></h5>
                                    <h6 className="card-text mb-3"> personal loan for home iron grill window & door</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={homegril} className="card-img-top" alt="homegril" />
                                <div className="card-body">
                                    <h5 className="card-title"><b> Personal Loan</b></h5>
                                    <h6 className="card-text mb-3"> personal loan for home iron grill window & door</h6>
                                    <a href="#" className="apmcloanberhampore">View Detials</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mt-3">
                            <a className='fonts' style={{ color: "maroon" }} href='tel: 9046237527'>any queries +91 9046237527</a>
                        </div>
                        <div className="col-md-5 mt-3">
                            <div className="card">
                                <div style={{ color: "darkBlue" }} class="card-header"><center>Check your eligibility</center></div>
                                <div className="card-body">
                                    <AddApplyloan />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <center><h3 className='blink' style={{color:"darkBlue"}}>Development under process</h3></center>
            <br />

        </div>
    )
}

export default PersonalLoan