import React from 'react'
import Navbar from '../inc/Navbar'

import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";


function PloanRule() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <ScrollToTop smooth top="100" color="darkBlue"/>
        <div className='murshidabadfinanceloan'>
                <div className="album py-0">
                    <div className="container ">
                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li style={{color:"white"}} className="breadcrumb-item"><Link style={{color:"white"}} to="/" href="#">Home</Link></li>
                                    <li style={{color:"white"}} className="breadcrumb-item"><a style={{color:"white"}} href="apply-loan">Apply Loan</a></li>
                             
                                </ol>
                            </nav>
                            <h5 style={{color:"white"}}>Personal loan for excavator repair</h5>
                        </div>
                    </div>
                </div>
            </div>


    </div>
  )
}

export default PloanRule